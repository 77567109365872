<template>
  <div class="relative w-full px-4 py-10 lg:px-8">
    <div
      class="relative h-full px-6 py-8 overflow-y-auto bg-white lg:px-12 rounded-xl"
    >
      <div class="flex items-center space-x-10">
        <router-link
          to="/account-settings/profile"
          class="flex-shrink-0 pb-2 pr-8 text-xs font-medium text-gray-type-8 hover:text-green-type-1"
          active-class="font-bold border-b text-green-type-1 border-green-type-1"
          >My profile</router-link
        >
        <router-link
          to="/account-settings/password"
          class="flex-shrink-0 pb-2 pr-8 text-xs font-medium text-gray-type-8 hover:text-green-type-1"
          active-class="border-b text-green-type-1 border-green-type-1"
          >Password</router-link
        >
        <router-link
          to="/account-settings/email-notifications"
          class="flex-shrink-0 pb-2 pr-8 text-xs font-medium text-gray-type-8 hover:text-green-type-1"
          active-class="border-b text-green-type-1 border-green-type-1"
          >Email notifications</router-link
        >
        <router-link
          to="/account-settings/administration"
          class="flex-shrink-0 hidden pb-2 pr-8 text-xs font-medium text-gray-type-8 hover:text-green-type-1"
          active-class="border-b text-green-type-1 border-green-type-1"
          >Administration</router-link
        >
      </div>
      <router-view class="mt-10"></router-view>
    </div>
  </div>
</template>